var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.dashboard ? _c('ModelEditor', {
    attrs: {
      "title": _vm.title,
      "model": _vm.dashboard,
      "errors": _vm.errors,
      "loading": _vm.$apollo.loading,
      "canSave": _vm.isDirty
    },
    on: {
      "save": _vm.save,
      "cancel": _vm.restore
    }
  }, [_vm.dashboard ? _c('DashboardStyled', [_c('AutoForm', {
    attrs: {
      "model": _vm.dashboard,
      "modelName": 'Dashboard',
      "filterFields": _vm.filterFields
    },
    on: {
      "update:model": function updateModel($event) {
        _vm.dashboard = $event;
      }
    }
  })], 1) : _vm._e()], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }