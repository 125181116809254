<template>
  <ModelEditor
    v-if="dashboard"
    :title="title"
    :model="dashboard"
    :errors="errors"
    :loading="$apollo.loading"
    :canSave="isDirty"
    @save="save"
    @cancel="restore"
  >
    <DashboardStyled v-if="dashboard">
      <AutoForm :model.sync="dashboard" :modelName="'Dashboard'" :filterFields="filterFields" />
    </DashboardStyled>
  </ModelEditor>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'

import ModelEditor from '@/components/misc/ModelEditor'
import { AutoForm } from '@common/components'
import DASHBOARD_FULL_QUERY from '#/graphql/operations/dashboard/dashboardList.gql'
import UPDATE_DASHBOARD_MUTATION from '#//graphql/operations/dashboard/updateDashboard.gql'

const DashboardStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: min-content;
`

export default {
  components: {
    DashboardStyled,
    ModelEditor,
    AutoForm,
  },
  data() {
    return {
      errors: [],
      dashboard: null,
      dashboardLocal: null,
      disabledFields: [],
      filterFields: ['ownerUserId', 'isEditable', 'isSharedBy'],
    }
  },
  computed: {
    title() {
      return `Dashboard ${get(this.dashboard, 'title', '')}`
    },
    isDirty() {
      return !isEqual(this.dashboard, this.dashboardLocal)
    },
  },
  methods: {
    async save() {
      const data = { ...this.dashboard }
      delete data.id
      delete data.__typename
      delete data.createdAt
      delete data.updatedAt
      this.filterFields.forEach(key => {
        delete data[key]
      })
      this.disabledFields.forEach(key => {
        delete data[key]
      })

      const res = await this.$apollo.mutate({
        mutation: UPDATE_DASHBOARD_MUTATION,
        variables: {
          where: {
            id: this.$route.params.id,
          },
          data,
        },
      })

      this.dashboardLocal = get(res, 'data.updateDashboard', null)
    },
    async restore() {
      this.dashboard = this.dashboardLocal
    },
    async addOrDeleteEntity(id, mode, entity) {
      const dashboardID = this.$route.params.id
      if (!dashboardID || !entity || !mode) {
        return
      }
      await this.$apollo.mutate({
        mutation: UPDATE_DASHBOARD_MUTATION,
        variables: {
          where: {
            id: dashboardID,
          },
          data: {
            [entity]: {
              [mode]: {
                id,
              },
            },
          },
        },
      })
    },
  },
  apollo: {
    dashboard: {
      query: DASHBOARD_FULL_QUERY,
      variables() {
        return {
          where: {
            id: this.$route.params.id,
          },
        }
      },
      manual: true,
      result({ data }) {
        this.dashboard = get(data, 'dashboard')
        this.dashboardLocal = get(data, 'dashboard')
      },
    },
  },
}
</script>
